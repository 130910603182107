<template>
  <div>
    <TopNav />

    <div class="container">
      <h1>About</h1>
    </div>

    <Footer />
  </div>
</template>

<script>
// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
