<template>
  <div>
    <div class="market-hide-show" id="exchange-page--market-element">
      <div class="market-pairs">
        <div class="tab-content">
          <div class="tab-pane fade show active" role="tabpanel">
            <table border="0" cellspacing="0" cellpadding="0" class="table">
              <!-- <thead>
                <tr>
                  <th class="text-left">Pairs</th>
                  <th>Price & Change</th>
                </tr>
              </thead> -->
              <tbody>
                <tr v-for="item in markets" :key="item.id">
                  <td class="text-left" @click="gotoMarket(item)">
                    <small>#{{ item.market_id_bc }}</small>
                    {{ item.pair_show }}
                  </td>
                  <td
                    :class="`text-right ${item.theclass}`"
                    @click="gotoMarket(item)"
                  >
                    {{ item.last_price }} <br />
                    {{
                      themain.angkaDenganPersentaseBesar(
                        parseFloat(item.change_24h)
                      )
                    }}%
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="btn_load mx-auto" @click="loadMorePage">
              Load More
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookie from "js-cookie";
import { toRaw } from "vue";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  data() {
    return {
      themain,

      markets: [],
      nomarkets: [],

      modalItem: {
        simbols: ["a", "b"],
        a_uni_icon: "",
        b_uni_icon: "",
      },

      pageObj: {
        page: 1,
        rows: 10,
        sort: "id",
        order: "asc",
        filter_rules: [],
      },
    };
  },

  async mounted() {
    var self = this;

    // ambil config untuk market yang di hide oleh user ini
    await self.getUsermarketconfigs();

    // ambil semua markets
    await self.getMarkets();

    // tracking perubahan data dari component lain
    self.emitter.on("fe-market_update", async function (e) {
      if (e.data) {
        var lamas = self.markets; // tampung data lama
        self.markets = []; // kosongkan markets
        var barus = [];

        lamas.forEach((ele) => {
          if (ele.id == e.data.id) {
            barus.push(e.data);
          } else {
            barus.push(ele);
          }
        });
        await self.upgradeMarkets(barus);
      }
    });

    // listen apakah ada show hide market untuk screen kecil
    self.emitter.on("fe-show-hide-market-for-small-screen", function () {
      var el = document.getElementById("exchange-page--market-element");

      // console.log("he", el.clientHeight);

      // tidak bisa mendetek display karena hasilnya jika pindah halaman, jadi bug
      // sehingga disini kita tambahkan saja client heigh dan lebih baik detek client heigh saja
      if (el.clientHeight > 0) {
        el.style.display = "none";
        el.style.height = "0px";
      }

      if (el.clientHeight == 0) {
        el.style.display = "block";
        el.style.height = "300px";
      }
    });
  },
  methods: {
    async getUsermarketconfigs() {
      var self = this;
      // jika gak ada yang terkoneksi, maka gak perlu ditracking yang dibawah ini
      if (Cookie.get("connectedAddress")) {
        var res = await thestore.getUsermarketconfigs(1, 20, "id", "desc", [
          {
            field: "user_address",
            op: "equal",
            value: `${Cookie.get("connectedAddress").toLowerCase()}`,
          },
        ]);
        self.nomarkets = res.datas;
      }
    },

    async getMarkets() {
      var self = this;
      var res = await thestore.getMarkets(
        self.pageObj.page,
        self.pageObj.rows,
        self.pageObj.sort,
        self.pageObj.order,
        self.pageObj.filter_rules
      );
      await self.upgradeMarkets(res.datas);
    },

    async upgradeMarkets(plain_datas) {
      var self = this;

      var showMarket = function (mdat) {
        // jika kembali atau show default nya adalah true
        var kembali = true;

        // jika ada daftar market yang tidak ingin dilihat, dilakukan perulangan
        self.nomarkets.forEach(function (ele) {
          if (
            mdat.sc_address.toLowerCase() == ele.sc_address.toLowerCase() &&
            parseInt(mdat.market_id_bc) == parseInt(ele.market_id_bc)
          ) {
            // jika ketemu market yang tidak ingin dilihat, disembunyikan disini
            kembali = false;
          } else {
            kembali = true;
          }
        });
        return kembali;
      };

      // data harus kita olah lagi
      var mars = [];
      var showMe = false; // ditempatkan diluar karena secara default adalah false
      plain_datas.forEach((ele) => {
        showMe = showMarket(ele);

        if (showMe == true) {
          // parsing
          var simbols = ele.pair.split("-");
          ele["pair_show"] = `${simbols[0]}/${simbols[1]}`;
          ele["simbols"] = simbols;

          if (showMe == true) {
            ele["show"] = "";
          } else {
            ele["show"] = "display: none";
          }

          if (ele.change_24h >= 0) {
            ele["theclass"] = "green";
          } else {
            ele["theclass"] = "red";
          }

          mars.push(ele);
        }
      });

      self.markets = mars;
    },

    /**
     * Untuk mengarahkan ke halaman exchange yang dipilih
     * @param {*} item object market
     */
    gotoMarket(item) {
      var self = this;
      self.$router.push({
        path: `/${themain.getChosenChainId()}/markets/${item.sc_address}/${
          item.market_id_bc
        }`,
        query: {
          pair: item.pair,
          mtipe: item.mtipe,
        },
      });
    },

    async loadMorePage() {
      var self = this;

      self.pageObj.page = self.pageObj.page + 1;

      var res = await thestore.getMarkets(
        self.pageObj.page,
        self.pageObj.rows,
        self.pageObj.sort,
        self.pageObj.order,
        self.pageObj.filter_rules
      );

      // remove proxy
      var oldData = [];
      self.markets.forEach(function (ele) {
        oldData.push(toRaw(ele));
      });
      console.log("oldData", oldData);

      var newData = oldData.concat(res.datas);
      console.log("newData", newData);

      self.upgradeMarkets(newData);
    },
  },
};
</script>

<style scoped>
/*
bagian ini membuat table yang berantakan menjadi rapi
lihat diskusi ini https://stackoverflow.com/questions/41534794/table-rows-not-aligning
sample berantakan: https://jsfiddle.net/fk6fnto4/
dirapikan: https://jsfiddle.net/fk6fnto4/1/
 */

.market-pairs {
  /* background-color: #161a1f !important; */
  border: none !important;
  box-shadow: none !important;
  height: 100% !important;
}

.market-pairs tbody {
  height: 700px;
}
.market-pairs thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.market-pairs tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.market-pairs td,
th {
  padding: 6px;
  text-align: right;
  overflow-y: scroll;
}

.market-hide-show {
  display: block;
  width: 100%;
  height: 100%;
}

.btn_load {
  width: max-content;
  background-color: rgb(14, 203, 129);
  padding: 5px 30px;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
}

td {
  /* background-color: #161a1f !important; */
  font-size: 12px;
}

table {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none !important;
}

table td {
  border: none !important;
}

@media only screen and (max-width: 884px) {
  small {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .market-pairs tbody {
    height: 400px;
  }
}
</style>
