<template>
    <main class="main_wrapper">
        <div class="text-center text-light">
            <h1>404</h1>
            <h4>PAGE NOT FOUND</h4>
            <button @click="routeToHome" class="btn btn-sm btn-gradient mt-4">Back To Home</button>
        </div>
    </main>
</template>

<script>
export default {
    methods: {
        routeToHome() {
            this.$router.push('/')
        }
    }
}
</script>

<style scoped>
h1 {
    font-size: 5rem;
    font-weight: bold;
    line-height: 4rem;
}
h4 {
    font-weight: 600;
}
.main_wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>