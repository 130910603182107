<template>
  <button
    @click="copyHandler"
    type="button"
    ref="copyButton"
    :style="positionStyles"
    class="d-flex align-items-center p-0 m-0 bg-transparent text-muted"
  >
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        stroke-linejoin="round"
        stroke-width="2"
        d="M9 8v3a1 1 0 0 1-1 1H5m11 4h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-7a1 1 0 0 0-1 1v1m4 3v10a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-7.13a1 1 0 0 1 .24-.65L7.7 8.35A1 1 0 0 1 8.46 8H13a1 1 0 0 1 1 1Z"
      />
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    value: String,
    // top: { type: Number, default: 43.5 },
    // right: { type: Number, default: 1 },
  },

  computed: {
    // positionStyles() {
    //   return {
    //     top: `${this.top}%`,
    //     right: `${this.right}%`,
    //   };
    // },
  },

  methods: {
    copyHandler() {
      if (this.value) {
        navigator.clipboard.writeText(this.value);

        const buttonEl = this.$refs.copyButton;
        buttonEl.classList.add("active");

        setTimeout(() => {
          buttonEl.classList.remove("active");
        }, 2500);
      }
    },
  },
};
</script>

<style scoped>
button {
  width: max-content;
  height: 56.5%;
  border: none;
  position: relative;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  outline: none;
}

button::before {
  content: "Copied";
  position: absolute;
  top: -45px;
  right: -20px;
  background: #5c81dc;
  color: white;
  padding: 8px 10px;
  border-radius: 20px;
  font-size: 15px;
  display: none;
}

button::after {
  content: "";
  position: absolute;
  top: -15px;
  right: 7px;
  width: 10px;
  height: 10px;
  background: #5c81dc;
  transform: rotate(45deg);
  display: none;
}

button.active::before,
button.active::after {
  display: block;
}

button svg {
  color: #498ac9;
}

button:active svg {
  color: #396a9b;
}
</style>
