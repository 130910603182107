// node_modules
import axios from "axios";
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import balance from "@/applibs/eth/balance.js";
import balanceObj from "@/applibs/erc20/balance-obj.js";
import buys from "@/applibs/exchange/buys.js";

var utama = {
  async getUser(user_address) {
    var userRes = await axios({
      method: "get",
      url: `${themain.baseApi}/users/get-by-address?address=${user_address}`,
    });
    return userRes.data.data;
  },

  async getAirdrops(page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      url: `${
        themain.baseApi
      }/airdrops?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async getMarkets(page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/markets/${Cookie.get(
        "chosenChainId"
      )}?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async getMarketsByCtAddresses(addresses) {
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/markets/${Cookie.get(
        "chosenChainId"
      )}/by-ct-addresses?addresses=${addresses}`,
    });
    return res.data.datas;
  },

  async getMarket(sc_address, market_id_bc) {
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/markets/${Cookie.get(
        "chosenChainId"
      )}/detail?sc_address=${sc_address}&market_id_bc=${market_id_bc}`,
    });
    return res.data.data.market;
  },

  async getCryptos(page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/cryptos/${Cookie.get(
        "chosenChainId"
      )}?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async getBuys(page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/buys/${Cookie.get(
        "chosenChainId"
      )}?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async getSells(page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/sells/${Cookie.get(
        "chosenChainId"
      )}?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  async getObooks(page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/obooks/${Cookie.get(
        "chosenChainId"
      )}?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  /**
   * Mendapatkan data orderbook berdasarkan sc_address, market_id_bc, buy_id or sell_id
   * @param {*} sc_address
   * @param {*} market_id_bc
   * @param {*} buy_id_bc
   * @param {*} sell_id_bc
   * @returns
   */
  async getObookByMIdScBcId(sc_address, market_id_bc, buy_id_bc, sell_id_bc) {
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/obooks/${Cookie.get(
        "chosenChainId"
      )}/by--sc-mid-id?sc_address=${sc_address}&market_id_bc=${market_id_bc}&buy_id_bc=${buy_id_bc}&sell_id_bc=${sell_id_bc}`,
    });
    return res.data.data;
  },

  async getP2pBuys(page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/p2p/${Cookie.get(
        "chosenChainId"
      )}/buys?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  // TODO: Dimatikan sementara
  async getArticles() {
    return;
  },
  // async getArticles(page, rows, sort, order, filter_rules) {
  //   var res = await axios({
  //     method: 'get',
  //     url: `${themain.baseApi}/articles?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(filter_rules)}`,
  //   });
  //   return res.data.data;
  // },

  async getUsermarketconfigs(page, rows, sort, order, filter_rules) {
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/usermarketconfigs/${Cookie.get(
        "chosenChainId"
      )}?page=${page}&rows=${rows}&sort=${sort}&order=${order}&filter_rules=${JSON.stringify(
        filter_rules
      )}`,
    });
    return res.data.data;
  },

  /**
   * Digunakan untuk cek apakah data yang ada pada wallet user sama dengan data yang
   * disajikan dari server kita.
   * Jika data tidak sama, maka ada tindak lanjutan untuk sync data ini
   */
  async buyFeCheck(sc_address, buy_id) {
    const res = await buys(buy_id);

    console.log("res", res);
  },

  /**
   * Pada function ini, kita mencari balance dari address yang terkoneksi
   * dan kita emit supaya komponen yang membutuhkan dapat menggunakan
   * balance yang baru
   * @param {*} theself
   */
  async emitNewMainBalance(theself) {
    if (
      Cookie.get("connectedAddress") != null &&
      Cookie.get("connectedAddress") != ""
    ) {
      var mainBalance = await balance(Cookie.get("connectedAddress"), "ether");
      theself.emitter.emit("fe-main_balance", mainBalance);
    }
  },

  async emitNewBalanceForAMarketTokenVsToken(theself) {
    if (
      Cookie.get("connectedAddress") != null &&
      Cookie.get("connectedAddress") != ""
    ) {
      // token_a
      var bal_a = await balanceObj(
        Cookie.get("connectedAddress"),
        theself.market.a_address
      );
      console.log("bal_a", bal_a);

      // token_b
      var bal_b = {};
      if (theself.market.b_address == "x") {
        // ini adalah coin (biasanya ETH);
        var coin_bal_b = await balance(Cookie.get("connectedAddress"), "ether");
        console.log("coin_bal_b", coin_bal_b);
        bal_b["balance"] = coin_bal_b;

        var coin_bal_b_wei = await balance(
          Cookie.get("connectedAddress"),
          "wei"
        );
        bal_b["balanceRaw"] = {};
        bal_b["balanceRaw"]["balance"] = coin_bal_b_wei;
      } else {
        // ini adalah token
        bal_b = await balanceObj(
          Cookie.get("connectedAddress"),
          theself.market.b_address
        );
      }
      console.log("bal_b", bal_b);

      theself.emitter.emit("fe-balance_update", { bal_a, bal_b });
    }
  },

  getBlockchainExplorerDomain() {
    // dapatkan dulu chain yang sedang dipilih
    const chosenChainId = themain.getChosenChainId();

    // setelah itu cari object-nya
    const chosenChainObj = themain.getChosenChainObj(chosenChainId);

    // return blochchainExplorer-nya saja
    return chosenChainObj['explorer'];
  }
};

export default utama;
