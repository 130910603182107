<template>
  <div>
    <TopNav />

    <div class="landing-hero">
      <div class="container">
        <div class="row landing-text-box">
          <div class="col-md-8">
            <h2 class="tagline">YOUR GATEWAY TO THE FUTURE WORLD</h2>
            <p class="tagline_desc">
              Trading digital assets and physical tokens using modern technology
              using web3 and blockchain.
            </p>

            <div
              v-if="isConnected == 'no'"
              class="input-group"
              id="auth_menu_button"
            >
              <div class="connect_se-m">
                <router-link
                  @click="handleConnect"
                  to="#"
                  class="btn btn-lg btn-outline-yellow"
                >
                  Connect
                </router-link>
              </div>
            </div>
          </div>

          <div class="offset-md-1 col-md-3">
            <!-- TradingView Widget BEGIN -->
            <div
              class="tradingview-widget-container tradingview-tecnical-analys"
            >
              <div class="tradingview-widget-container__widget"></div>
            </div>
            <!-- TradingView Widget END -->
          </div>
        </div>
      </div>
    </div>

    <div class="container popular-markets">
      <h2>Airdrops</h2>
      <AirdropTable />
    </div>

    <div class="container popular-markets">
      <h2>Popular Markets</h2>
      <MarketTable />
    </div>

    <div class="landing-feature">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2>WHY DEXGOOD</h2>
          </div>
          <div class="col-md-4">
            <div class="landing-feature-item box_feature">
              <img src="assets/img/landing/stroge.svg" alt="" />
              <h3>Your wallet</h3>
              <p>
                You trade directly from your wallet and we don't hold your
                assets so it's impossible for us to play with your assets.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="landing-feature-item box_feature">
              <img src="assets/img/landing/backup.svg" alt="" />
              <h3>Web3</h3>
              <p>
                DEXGOOD guarantees the decentralization that is the foundation
                of web3.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="landing-feature-item box_feature">
              <img src="assets/img/landing/managment.svg" alt="" />
              <h3>Transparent</h3>
              <p>
                All data is stored in the blockchain so you can view it easily.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="landing-number">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <h2>$657B</h2>
            <p>Quarterly volume traded</p>
          </div>
          <div class="col-md-4">
            <h2>100+</h2>
            <p>Countries supported
            </p>
          </div>
          <div class="col-md-4">
            <h2>56+M</h2>
            <p>Verified users
            </p>
          </div>
        </div>
      </div>
    </div> -->

    <div class="landing-feature landing-start">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h2>Get started in just 2 steps</h2>
          </div>
          <div class="col-md-6">
            <div class="landing-feature-item">
              <img src="assets/img/landing/user.svg" alt="" />
              <span class="m-2">1</span>
              <h3>Connect</h3>
            </div>
          </div>
          <!-- <div class="col-md-4">
            <div class="landing-feature-item">
              <img src="assets/img/landing/bank.svg" alt="">
              <span>
                2
              </span>
              <h3>Link your bank account </h3>
            </div>
          </div> -->
          <div class="col-md-6">
            <div class="landing-feature-item">
              <img src="assets/img/landing/trade.svg" alt="" />
              <span class="m-2">2</span>
              <h3>Start buying & selling</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import detectEthereumProvider from "@metamask/detect-provider";
import axios from "axios";
import MobileDetect from "mobile-detect";
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import MarketTable from "@/components/global/MarketTable.vue";
import AirdropTable from "@/components/global/AirdropTable.vue";

export default {
  components: {
    TopNav,
    Footer,
    MarketTable,
    AirdropTable,
  },

  data() {
    return {
      themain,
      logout: "yes",

      accountConnected: null,
      errorMessage: null,
      isError: false,
      hasProvider: false,
      isConnected: Cookie.get("connected"),
      domainName: process.env.VUE_APP_DOMAIN_NAME,
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected");
    },

    market_update: function (data) {
      var self = this;

      var market = data.market;

      self.emitter.emit("fe-market_update", {
        data: market,
      });
    },
  },

  async beforeMount() {
    var self = this;

    try {
      themain.beforeMountConnectToWallet(self);
      // themain.networkAlert();
      // themain.networkChanged(self);
      // themain.accountChanged(self);
    } catch (error) {
      console.log("error", error);
    }
  },

  async mounted() {
    var self = this;
    self.getProvider();
  },

  methods: {
    /**
     * Ini dicopy dari component topnav
     */
    async getProvider() {
      var self = this;

      const provider = await detectEthereumProvider({ silent: true });
      self.hasProvider = Boolean(provider);

      if (provider) {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        self.refreshAccounts(accounts);
        window.ethereum.on("accountsChanged", self.refreshAccounts);
      }
    },

    refreshAccounts(accounts) {
      var self = this;

      if (accounts.length > 0) {
        self.updateWallet(accounts);
      } else {
        self.accountConnected = null;
        Cookie.set("connected", "no");
        Cookie.remove("connectedAddress");
        self.isConnected = "no";
      }
    },

    async updateWallet(accounts) {
      var self = this;

      if (
        accounts.length > 0 &&
        accounts[0] !== Cookie.get("connectedAddress")
      ) {
        window.location.reload();
      }

      self.accountConnected = accounts;
      self.registerMyAccount(accounts);
      Cookie.set("connectedAddress", accounts[0]);
    },

    async checkWallet() {
      var self = this;
      let userAgent = navigator.userAgent.toString();
      const isMobile = new MobileDetect(window.navigator.userAgent);
      const isMetaMaskInstalled = typeof window.ethereum !== "undefined";
      if (isMetaMaskInstalled) {
        self.handleConnect();
      } else if (isMobile.mobile()) {
        if (userAgent.includes("MetaMaskMobile")) {
          self.handleConnect();
        } else {
          window.location.href = `https://metamask.app.link/dapp/${self.domainName}`;
          return;
        }
      } else {
        var rpcOption = {};
        rpcOption[process.env.VUE_APP_CHAIN_ID] = process.env.VUE_APP_RPC_URL;

        window.ethProvider = new WalletConnectProvider({
          rpc: rpcOption,
        });
        //  Enable session (triggers QR Code modal)
        await window.ethProvider.enable();
        const web3 = new Web3(window.ethProvider);
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          await self.registerMyAccount(accounts);
        } else {
          alert("Please connect your address");
        }
      }
    },

    async handleConnect() {
      var self = this;
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        self.isError = false;
        self.updateWallet(accounts);
        console.log("account:", accounts);
        Cookie.set("connected", "yes");

        Cookie.set("connectedAddress", accounts[0]);

        window.location.reload();
      } catch (err) {
        self.isError = true;
        self.errorMessage = err.message;
        Cookie.set("connected", "no");
      }
    },

    async registerMyAccount(accounts) {
      // var self = this;
      // jika belum ada akun, akan ter-register langsung di server
      axios
        .post(`${themain.baseApi}/users/connect-me`, {
          address: accounts[0],
        })
        .then(async (res) => {
          if (res.data.success == true) {
            console.log("resRedister: ", res);
          }
        });
    },
  },
};
</script>

<style scoped>
.landing-hero {
  width: 100%;
  height: 75vh; /* vh stands for viewport height */
  background-image: url("/images/hero-background-05.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px;
}

.landing-text-box {
  padding-top: 18%;
}

.btn-outline-orange {
  color: #fff;
  padding: 12px 30px;
  border: 1px solid #fff;
  margin-right: 20px;
  box-shadow: 0 0 15px #ff666d;
  /* background-color: #ff666d; */
}

.btn-outline-yellow {
  color: #fff;
  padding: 12px 30px;
  border: 1px solid #fff;
  margin-right: 20px;
  box-shadow: 0 0 15px #ffc070;
  /* background-color: #ffc070; */
}

.landing-hero p {
  font-size: 18px;
}

.popular-markets {
  margin-bottom: 3rem;
}

.popular-markets h2 {
  margin: 50px 0px 50px 0px;
  color: #fff;
}

.connect_se-m {
  display: none;
}

.box_feature {
  height: 100%;
  max-height: 19rem;
}

@media only screen and (max-width: 991px) {
  .landing-text-box {
    padding-top: 150px;
  }
  .landing-hero h2 {
    font-size: 30px !important;
  }
  .landing-hero p {
    font-size: 15px;
  }
  .btn-outline-orange,
  .btn-outline-yellow {
    padding: 8px 20px;
    margin-right: 15px;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .connect_se-m {
    display: block;
  }
}
</style>
