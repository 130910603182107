import jsonInterface from "./abi";
import themain from "@/applibs/themain.js";
import Web3 from "web3";

var utama = async function (fromAddress, buyIds) {
  const chainId = themain.getChosenChainId();
  const chainObj = themain.getChosenChainObj(chainId);

  const web3 = new Web3(window.ethereum);

  var myContract = new web3.eth.Contract(jsonInterface, chainObj.sc_address);

  let sendOptions;
  if (chainId == "247253") {
    // saakuru testnet
    sendOptions = {
      from: fromAddress,
      gasPrice: "0x0",
    };
  } else if (chainId == "7225878") {
    // saakuru main net
    sendOptions = {
      from: fromAddress,
      gasPrice: "0x0",
    };
  } else {
    sendOptions = {
      from: fromAddress,
    };
  }

  const hasil = await myContract.methods
    .buyCancelMultiple(buyIds)
    .send(sendOptions);

  return hasil;
};

export default utama;
