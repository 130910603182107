<template>
  <div class="main_wrapper">
    <TopNav />

    <div class="container d-flex justify-content-center text-white">
      <div class="col-lg-10 my-5">
        <h2 class="mb-5 text-center">Frequently Asked Question</h2>

        <div class="mb-4">
          <h3 class="mb-2 fw-bold">What is DEX?</h3>
          <p>DEX is a decentralized exchange.</p>
          <p>
            All transaction data on DEX is on the blockchain, making this
            exchange the most transparent exchange.
          </p>
        </div>

        <div>
          <h2>How does DEX works?</h2>
          <p>The market on DEX is created by users.</p>
          <p>
            Users in DEX do not need to make a deposit because they trade
            directly from their respective wallets. Every purchase and sale is
            stored in the blockchain. If a purchase or sale occurs, the user
            immediately gets the results directly in his wallet without having
            to make a withdrawal.
          </p>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules

// applibs

import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
p {
  margin-bottom: unset;
}

.main_wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
