<template>
  <div>
    <table class="table my-open-order-table table-borderless">
      <thead class="position-relative">
        <tr>
          <th>Time</th>
          <th>Buy/Sell</th>
          <th>Price</th>
          <th>{{ simbol_a }}</th>
          <th>{{ simbol_b }}</th>
          <th>
            <div class="d-flex justify-content-end">
              <div
                @click="toggleDropdownMenu"
                ref="dropdown"
                class="dropdown_button"
                :class="dropdownMenu && 'menu_active'"
              >
                <svg
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#6e7d91"
                    stroke-linecap="round"
                    stroke-width="2"
                    d="M5 7h14M5 12h14M5 17h10"
                  />
                </svg>
              </div>

              <div v-show="dropdownMenu" class="dropdown_menu">
                <ul>
                  <li @click="themain.openModal(`myorders-cancel-all-sells-modal`)">Cancel All Sells</li>
                  <li @click="themain.openModal(`myorders-cancel-all-buys-modal`)">Cancel All Buys</li>
                </ul>
              </div>
            </div>

            <!-- calcel all sells -->
            <!-- <button
              class="btn-outline_custom-cancel"
              @click="themain.openModal(`myorders-cancel-all-sells-modal`)"
            >
              Cancel All Sells
            </button> -->
            <div
              class="modal fade text-dark"
              tabindex="-1"
              aria-hidden="true"
              v-bind:id="`myorders-cancel-all-sells-modal`"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal_body">
                    <div class="alert_modal">
                      <svg
                        width="10"
                        height="77"
                        viewBox="0 0 21 98"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2697 6C8.10247 6 6.12041 7.84191 6.26556 10.0041L8.26764 64.0602C8.26764 64.5912 8.47857 65.1004 8.85403 65.4759C9.22949 65.8513 9.73873 66.0622 10.2697 66.0622C10.8007 66.0622 11.3099 65.8513 11.6854 65.4759C12.0609 65.1004 12.2718 64.5912 12.2718 64.0602L14.2739 10.0041C14.419 7.84191 12.437 6 10.2697 6Z"
                          stroke="#FF0F18"
                          stroke-width="12"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.2698 91.2728C12.4812 91.2728 14.2739 89.48 14.2739 87.2686C14.2739 85.0572 12.4812 83.2645 10.2698 83.2645C8.05834 83.2645 6.26562 85.0572 6.26562 87.2686C6.26562 89.48 8.05834 91.2728 10.2698 91.2728Z"
                          stroke="#FF0F18"
                          stroke-width="12"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <h2>Are you sure?</h2>
                    <div class="btn_wrapper mt-4">
                      <button
                        type="button"
                        @click="
                          themain.closeModal(`myorders-cancel-all-sells-modal`)
                        "
                        :disabled="modalCloseBtnDisabled"
                      >
                        Cancel
                      </button>
                      <button type="button" @click="cancelAllSells()">
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- calcel all sells -->

            <!-- calcel all buys -->
            <!-- <button
              class="btn-outline_custom-cancel"
              @click="themain.openModal(`myorders-cancel-all-buys-modal`)"
            >
              Cancel All Buys
            </button> -->
            <div
              class="modal fade text-dark"
              tabindex="-1"
              aria-hidden="true"
              v-bind:id="`myorders-cancel-all-buys-modal`"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal_body">
                    <div class="alert_modal">
                      <svg
                        width="10"
                        height="77"
                        viewBox="0 0 21 98"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2697 6C8.10247 6 6.12041 7.84191 6.26556 10.0041L8.26764 64.0602C8.26764 64.5912 8.47857 65.1004 8.85403 65.4759C9.22949 65.8513 9.73873 66.0622 10.2697 66.0622C10.8007 66.0622 11.3099 65.8513 11.6854 65.4759C12.0609 65.1004 12.2718 64.5912 12.2718 64.0602L14.2739 10.0041C14.419 7.84191 12.437 6 10.2697 6Z"
                          stroke="#FF0F18"
                          stroke-width="12"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.2698 91.2728C12.4812 91.2728 14.2739 89.48 14.2739 87.2686C14.2739 85.0572 12.4812 83.2645 10.2698 83.2645C8.05834 83.2645 6.26562 85.0572 6.26562 87.2686C6.26562 89.48 8.05834 91.2728 10.2698 91.2728Z"
                          stroke="#FF0F18"
                          stroke-width="12"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <h2>Are you sure?</h2>
                    <div class="btn_wrapper mt-4">
                      <button
                        type="button"
                        @click="
                          themain.closeModal(`myorders-cancel-all-buys-modal`)
                        "
                        :disabled="modalCloseBtnDisabled"
                      >
                        Cancel
                      </button>
                      <button type="button" @click="cancelAllBuys()">
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- calcel all buys -->
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in myorders" :key="item">
          <td>
            {{ moment(item.created_at).format("YYYY-MM-DD HH:mm") }}
            <div class="trick" style="height: 0; overflow: hidden">
              {{ item }}
            </div>
          </td>
          <td>
            <span v-if="item.buyer == ''" class="buyer_ex"> Sell </span>
            <span v-if="item.seller == ''" class="seller_ex"> Buy </span>
          </td>
          <td>{{ item.price_db }}</td>
          <td>{{ parseFloat(item.qty_a_db).toFixed(6) }}</td>
          <td>{{ parseFloat(item.qty_b_db).toFixed(6) }}</td>
          <td>
            <button
              class="btn-outline_custom-cancel"
              @click="themain.openModal(`myorders-${item.id}-modal`)"
            >
              Cancel
            </button>

            <button
              class="btn-outline_custom-price"
              @click="themain.openModal(`myorders-move-${item.id}-modal`)"
            >
              Change Price
            </button>

            <div
              class="modal fade text-dark"
              tabindex="-1"
              aria-hidden="true"
              v-bind:id="`myorders-${item.id}-modal`"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal_body">
                    <div class="alert_modal">
                      <svg
                        width="10"
                        height="77"
                        viewBox="0 0 21 98"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2697 6C8.10247 6 6.12041 7.84191 6.26556 10.0041L8.26764 64.0602C8.26764 64.5912 8.47857 65.1004 8.85403 65.4759C9.22949 65.8513 9.73873 66.0622 10.2697 66.0622C10.8007 66.0622 11.3099 65.8513 11.6854 65.4759C12.0609 65.1004 12.2718 64.5912 12.2718 64.0602L14.2739 10.0041C14.419 7.84191 12.437 6 10.2697 6Z"
                          stroke="#FF0F18"
                          stroke-width="12"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.2698 91.2728C12.4812 91.2728 14.2739 89.48 14.2739 87.2686C14.2739 85.0572 12.4812 83.2645 10.2698 83.2645C8.05834 83.2645 6.26562 85.0572 6.26562 87.2686C6.26562 89.48 8.05834 91.2728 10.2698 91.2728Z"
                          stroke="#FF0F18"
                          stroke-width="12"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <h2>Are you sure?</h2>
                    <div class="btn_wrapper mt-4">
                      <button
                        type="button"
                        @click="themain.closeModal(`myorders-${item.id}-modal`)"
                        :disabled="modalCloseBtnDisabled"
                      >
                        Cancel
                      </button>
                      <button type="button" @click="cancelBuyOrSell(item)">
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade text-dark"
              tabindex="-1"
              aria-hidden="true"
              v-bind:id="`myorders-move-${item.id}-modal`"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal_body">
                    <div class="alert_modal">
                      <svg
                        width="50"
                        height="60"
                        viewBox="0 0 81 92"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_11_24-244108"
                          style="mask-type: luminance"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="81"
                          height="92"
                        >
                          <path
                            d="M2.60986 13.653L40.8241 2.5L79.0001 13.653V36.5234C78.9999 48.2444 75.3115 59.6682 68.4575 69.1763C61.6034 78.6844 51.9313 85.7948 40.8113 89.5C29.688 85.7952 20.0126 78.6839 13.1562 69.1737C6.29975 59.6636 2.61003 48.2368 2.60986 36.5128V13.653Z"
                            fill="white"
                            stroke="white"
                            stroke-width="4"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M25.9512 36.4512H55.6585L45.0487 25.8414M55.6585 49.1829H25.9512L36.5609 59.7927"
                            stroke="black"
                            stroke-width="4"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </mask>
                        <g mask="url(#mask0_11_24-244108)">
                          <path
                            d="M-10.1218 -3.86584H91.7318V97.9878H-10.1218V-3.86584Z"
                            fill="#0ECB81"
                          />
                        </g>
                      </svg>
                    </div>
                    <div class="form_items">
                      <label class="">Current Price</label>
                      <input
                        type="text"
                        class="form-control"
                        :value="`${item.price_db}`"
                      />
                    </div>

                    <div class="form_items">
                      <label class="">New Price</label>
                      <input
                        type="text"
                        class="form-control"
                        :ref="`new_price_${item.id}`"
                      />
                    </div>
                  </div>
                  <div class="btn_wrapper">
                    <button
                      type="button"
                      @click="
                        themain.closeModal(`myorders-move-${item.id}-modal`)
                      "
                      :disabled="modalCloseBtnDisabled"
                    >
                      Cancel
                    </button>
                    <button type="button" @click="moveBuyOrSell(item)">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// node_modules
import Big from "big.js";
import axios from "axios";
import moment from "moment";
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";
import buyCancel from "@/applibs/exchange/buy-cancel.js";
import buyCancelMultiple from "@/applibs/exchange/buy-cancel-multiple.js";
import sellCancel from "@/applibs/exchange/sell-cancel.js";
import sellCancelMultiple from "@/applibs/exchange/sell-cancel-multiple.js";
import moveBuy from "@/applibs/exchange/move-buy.js";
import moveSell from "@/applibs/exchange/move-sell.js";

// components

export default {
  components: {},
  props: {
    market: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      themain,
      moment,

      sc_address: this.$route.params.sc_address.toLowerCase(),
      market_id_bc: this.$route.params.market_id_bc,
      pair: this.$route.query.pair,
      mtipe: this.$route.query.mtipe,

      connectedAddress: Cookie.get("connectedAddress"),

      simbol_a: "",
      simbol_b: "",

      myorders: [],

      modalCloseBtnDisabled: false,
      showAlertModal: false,

      all_sell_ids: [],
      all_buy_ids: [],

      dropdownMenu: false,
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected my-open-orders-bot");
    },

    myorders: function (data) {
      var self = this;

      // hanya bisa diterima jika ada address yang terkoneksi
      if (self.connectedAddress != "") {
        // hanya diterima jika market sesuai
        if (
          parseInt(data.myorder.market_id_bc) == parseInt(self.market_id_bc) &&
          data.myorder.sc_address == self.sc_address
        ) {
          // jika sesuai dengan address yang terkoneksi
          if (
            data.myorder.seller == self.connectedAddress.toLowerCase() ||
            data.myorder.buyer == self.connectedAddress.toLowerCase()
          ) {
            self.getMyOrders();
          }
        }
      }
    },
  },

  async created() {},

  async mounted() {
    var self = this;

    setTimeout(async () => {
      await self.getMyOrders();

      // simbols
      var simbols = self.market.pair.split("-");
      self.simbol_a = simbols[0];
      self.simbol_b = simbols[1];

      console.log("self.market open", self.market);
    }, 3000);

    // connected Address jika bot maka diganti
    if (self.$route.query.bot == "true") {
      self.connectedAddress = self.$route.query.botAddress;
    }

    // event listener untuk menutup menu dropdown ketika klik sembarang
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    async getMyOrders() {
      var self = this;

      var filter_rules = [
        {
          field: "qty_a_db",
          op: "not-equal-num",
          value: "0",
        },
      ];

      var res = await axios({
        method: "get",
        url: `${
          themain.baseApi
        }/myorders/${themain.getChosenChainId()}/special?my_address=${
          self.connectedAddress
        }&sc_address=${self.sc_address}&market_id_bc=${
          self.market_id_bc
        }&filter_rules=${JSON.stringify(filter_rules)}`,
      });
      console.log("res myorders", res.data.data.datas);
      self.myorders = res.data.data.datas;

      // ------ sell ids -----
      // kosongkan dulu all_sell_ids sebelum ditimpa datanya;
      self.all_sell_ids = [];
      // array untuk menampung semua data sell_ids
      const sell_ids = [];
      res.data.data.datas.forEach(function (el) {
        if (el.seller != "") {
          sell_ids.push(el.buy_or_sell_id_bc.toString());
        }
      });
      self.all_sell_ids = sell_ids;
      // ------ sell ids -----

      // ------ buy ids -----
      // kosongkan dulu all_buy_ids sebelum ditimpa datanya;
      self.all_buy_ids = [];
      // array untuk menampung semua data buy_ids
      const buy_ids = [];
      res.data.data.datas.forEach(function (el) {
        if (el.buyer != "") {
          buy_ids.push(el.buy_or_sell_id_bc.toString());
        }
      });
      self.all_buy_ids = buy_ids;
    },

    /**
     * TODO: SAAT INI, INI TIDAK DIPAKAI KARENA MENYEBABKAN ADA DOUBLE PADA MY OPEN ORDERS
     * Memasukkan data yang baru yang datang dari emit frontend
     * @param {*} newDataObj
     */
    addNewMyorders(newDataObj) {
      var self = this;

      var my_orders_new = [];

      // mengganti data yang baru masuk
      var apa_ada = 0;
      self.myorders.forEach((ele) => {
        if (ele.id == newDataObj.id) {
          my_orders_new.push(newDataObj);
          apa_ada = apa_ada + 1;
        } else {
          my_orders_new.push(ele);
        }
      });

      // jika tidak ada data yang di update, maka dimasukkan saja data baru
      if (apa_ada == 0) {
        my_orders_new.push(newDataObj);
      }

      // periksa apakah ada qty yang 0
      // yang qty > 0, maka dimunculkan
      // Ini diperiksa berulang kali karena ketika pertama di load, memang kita tidak ambil data yang 0
      // Tetapi setelah dapat socket, yang qty 0 pun dikirim sehingga jika sudah berubah jadi 0, maka kita hilangkan saja dari myorders
      var myorders_arr = [];
      my_orders_new.forEach((ele) => {
        if (ele.qty_a_db > 0) {
          myorders_arr.push(ele);
        }
      });

      // ganti myorders
      self.myorders = myorders_arr;
    },

    // TODO: Update balance user setelah selesai cancel
    async cancelBuyOrSell(myorderObj) {
      var self = this;

      themain.closeModal(`myorders-${myorderObj.id}-modal`);

      if (myorderObj.buyer == "") {
        console.log("sell cancel");
        await sellCancel(self.connectedAddress, myorderObj.buy_or_sell_id_bc);
      } else {
        console.log("buy cancel");
        await buyCancel(self.connectedAddress, myorderObj.buy_or_sell_id_bc);
      }

      // emit karena mungkin ada perubahan balance
      thestore.emitNewBalanceForAMarketTokenVsToken(self);
    },

    // TODO: Move sell atau buy gak boleh lewat dari row buy atau row sell
    async moveBuyOrSell(myorderObj) {
      var self = this;

      console.log("myorderObj", JSON.stringify(myorderObj));

      if (myorderObj.buyer == "") {
        console.log("move sell", myorderObj);

        // cari order id dalam blockchain
        var sellIdIc = myorderObj.buy_or_sell_id_bc;
        console.log("sellIdIc", sellIdIc);

        // cari harga baru dari form
        const newPriceEl = self.$refs[`new_price_${myorderObj.id}`][0].value;

        // pangkat berapa? Apakah pangkat dari coin A atau dari coin B?
        // ambil B decimals
        const newPriceSend = Big(newPriceEl)
          .times(Big(`1e${self.market.b_decimals}`))
          .toString();

        console.log("newPriceSend", Big(newPriceSend).toFixed());

        // Baris code ini tidak bisa diletakkan terakhir karena element dihapus
        // waktu berhasil di move
        themain.closeModal(`myorders-${myorderObj.id}-modal`);

        // artinya move sell
        await moveSell(
          self.connectedAddress,
          sellIdIc,
          Big(newPriceSend).toFixed() // karena ada simbol eksponensial, maka disini ditambahakn toFixed
        );
      } else {
        console.log("move buy", myorderObj);

        // cari order id dalam blockchain
        var buyIdIc = myorderObj.buy_or_sell_id_bc;
        console.log("buyIdIc", buyIdIc);

        // cari harga baru dari form
        const newPriceEl = self.$refs[`new_price_${myorderObj.id}`][0].value;

        // pangkat berapa? Apakah pangkat dari coin A atau dari coin B?
        // ambil B decimals
        const newPriceSend = Big(newPriceEl)
          .times(Big(`1e${self.market.b_decimals}`))
          .toString();

        console.log("newPriceSend", newPriceSend);

        // artinya cancel buy
        await moveBuy(self.connectedAddress, buyIdIc, newPriceSend);
        themain.closeModal(`myorders-${myorderObj.id}-modal`);
      }
    },

    async cancelAllSells() {
      var self = this;
      themain.closeModal(`myorders-cancel-all-sells-modal`);
      await sellCancelMultiple(self.connectedAddress, self.all_sell_ids);
    },

    async cancelAllBuys() {
      var self = this;
      themain.closeModal(`myorders-cancel-all-buys-modal`);
      await buyCancelMultiple(self.connectedAddress, self.all_buy_ids);
    },

    toggleDropdownMenu() {
      this.dropdownMenu = !this.dropdownMenu;
    },

    handleClickOutside(event) {
      const dropdown = this.$refs.dropdown;
      if (dropdown && !dropdown.contains(event.target)) {
        this.dropdownMenu = false;
      }
    }
  },
};
</script>

<style>
.my-open-order-table thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.my-open-order-table tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.my-open-order-table td,
th {
  padding: 5px;
  text-align: right;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none !important;
}

.btn-outline_custom-price {
  border: 1px solid #01663f;
  background: #0ecb81;
  border: none;
  color: aliceblue;
}

.btn-outline_custom-price:hover {
  border: 1px solid #08a167;
}

.btn-outline_custom-cancel {
  border: 1px solid #db0c28;
  background: #f6465d;
  color: aliceblue;
  border: none;
}

.btn-outline_custom-cancel:hover {
  border: 1px solid #e02740;
}

.modal_body {
  background-color: #1e2329;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal_body h2 {
  font-size: 28px;
  color: #f6465d;
}

.modal_body p {
  color: rgb(201, 202, 202);
  text-align: center;
}

.modal-dialog {
  width: 350px;
}

.alert_modal {
  width: 120px;
  height: 120px;
  background: #1e2329;
  border-radius: 100%;
  border: 10px solid #161a1f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -70px;
}

.btn_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn_wrapper button {
  width: 100%;
  height: 50px;
  border: none;
  color: aliceblue;
}

.btn_wrapper button:nth-child(1) {
  background: #08a167;
}

.btn_wrapper button:nth-child(2) {
  background: #e02740;
}

.form_items {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form_items label {
  padding: 0 0 5px 0 !important;
  margin: 0 0 5px 0 !important;
  color: rgb(201, 202, 202);
  font-size: 16px;
}

.form_item input {
  width: 100%;
}

.buyer_ex {
  color: #f6465d;
}

.seller_ex {
  color: #08a167;
}
</style>

<style scoped>
.dropdown_button {
  width: max-content;
  padding: 0.1rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
}

.dropdown_button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu_active {
  background-color: rgba(255, 255, 255, 0.1);
}

.dropdown_menu {
  position: absolute;
  top: 100%;
  background-color: #1e2329;
  color: #ff4c4c;
}

.dropdown_menu ul li {
  padding: 0.25rem 0.6rem;
}

.dropdown_menu ul li:not(:last-child) {
  border-bottom: 1px solid #3a3f47;
}

.dropdown_menu ul li:hover {
  background-color: #2f3741;
}
</style>
