<template>
  <div class="wrapper">
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <div class="tab-content" id="v-pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="settings-profile"
                role="tabpanel"
                aria-labelledby="settings-profile-tab"
              >
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">General Information</h5>
                    <div class="settings-profile">
                      <form>
                        <img
                          src="../../../public/assets/img/avatar.svg"
                          alt="avatar"
                          width="50"
                        />
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="fileUpload"
                          />
                          <label class="custom-file-label" for="fileUpload">
                            Choose avatar
                          </label>
                        </div>
                        <div class="form-row mt-4">
                          <div class="col-md-6">
                            <label for="formFirst">First name</label>
                            <input
                              id="formFirst"
                              type="text"
                              class="form-control"
                              placeholder="First name"
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="formLast">Last name</label>
                            <input
                              id="formLast"
                              type="text"
                              class="form-control"
                              placeholder="Last name"
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="emailAddress">Email</label>
                            <input
                              id="emailAddress"
                              type="text"
                              class="form-control"
                              placeholder="Enter your email"
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="phoneNumber">Phone</label>
                            <input
                              id="phoneNumber"
                              type="text"
                              class="form-control"
                              placeholder="Enter phone number"
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="selectLanguage">Language</label>
                            <select id="selectLanguage" class="custom-select">
                              <option selected>English</option>
                              <option>Mandarin Chinese</option>
                              <option>Spanish</option>
                              <option>Arabic</option>
                              <option>Russian</option>
                            </select>
                          </div>
                          <div class="col-md-6">
                            <label for="selectCurrency">Currency</label>
                            <select id="selectCurrency" class="custom-select">
                              <option selected>USD</option>
                              <option>EUR</option>
                              <option>GBP</option>
                              <option>CHF</option>
                            </select>
                          </div>
                          <div class="col-md-12">
                            <input type="submit" value="Update" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules

// applibs
import themain from "@/applibs/themain.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";

export default {
  components: {
    TopNav,
    Footer,
    Menu,
  },
  data() {
    return {
      themain,
    };
  },

  async beforeMount() {
    var self = this;
    themain.beforeMountConnectToWallet(self);
    themain.networkAlert();
    themain.networkChanged(self);
    themain.accountChanged(self);
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-file {
  width: 35%;
}

@media only screen and (max-width: 500px) {
  .custom-file {
    width: 75%;
  }
}
</style>
