import myEthCall from "../eth/call";
import Web3 from "web3";

/**
 * Digunakan untuk mendapatkan token symbol, token decimals
 * @param {*} userAddress
 * @returns {decimals: 18, symbol: 'XXX', balanceRaw: "1000000000000000000", balance: '1'}
 */
var utama = async function (userAddress, contractAddress) {
  var web3 = new Web3(window.ethereum);

  if (userAddress != null && userAddress != "" && userAddress != undefined) {
    // console.log('contractAddress', contractAddress);

    // 1. ambil token decimals
    // {"0": "18"}
    var decimals = await myEthCall(
      web3,
      contractAddress,
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{ name: "", type: "uint8" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      []
    );
    // console.log('decimals', decimals);
    // console.log("decimals['0']", decimals['0']);

    // 2. ambil symbol token
    // hasilnya {"0": "USDT"}
    var tknSymbol = await myEthCall(
      web3,
      contractAddress,
      {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [{ name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      []
    );
    // console.log('tknSymbol', tknSymbol);

    // balance raw
    var balanceRaw = await myEthCall(
      web3,
      contractAddress,
      {
        constant: true,
        inputs: [{ name: "_owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "balance", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      [userAddress]
    );
    // console.log('balanceRaw', balanceRaw);

    // 4. kalkulasi balance untuk ditampilkan
    // balance = balanceRaw / 10**18
    var balanceRawBn = web3.utils.toBN(balanceRaw["balance"]);
    var balanceWow = balanceRawBn / 10 ** parseInt(decimals["0"]);
    //console.log('balanceWow', balanceWow);

    return {
      decimals: parseInt(decimals["0"]),
      symbol: tknSymbol["0"],
      balanceRaw: balanceRaw,
      balance: balanceWow,
    };
  } else {
    return null;
  }
};

export default utama;
