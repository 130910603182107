<template>
  <div>
    <TopNav />

    <section class="container">
      <section class="row">
        <div class="col">
          <h1 class="text-light mt-4">New Article</h1>

          <form class="row mt-4" id="create-article-form">
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  v-model="theform.title"
                  class="form-control"
                  placeholder="Title"
                />
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col">
              <div class="btn btn-dark" @click="submitForm()">Submit</div>

              <div class="btn btn-dark" @click="submitFormTwo()">Send</div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Web3 from "web3";
import Cookie from "js-cookie";
import {
  fromRpcSig,
  hashPersonalMessage,
  ecrecover,
  pubToAddress,
} from "ethereumjs-util";

// applibs
import themain from "@/applibs/themain.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      themain,

      theform: {
        title: "",
      },
    };
  },

  methods: {
    async submitForm() {
      // var self = this;

      var web3;
      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
      } else {
        web3 = new Web3(window.ethProvider);
      }
      // The message you want to sign
      const message = "I want to get my public key";

      // Get the user's connected Ethereum address
      const userAddress = Cookie.get("connectedAddress");

      try {
        // Sign the message using Metamask
        const signature = await web3.eth.personal.sign(message, userAddress);

        // Ensure the input is a Buffer
        const signatureBuffer = Buffer.from(signature.slice(2), "hex"); // Remove the '0x' prefix

        // Extract the components from the signature
        const { v, r, s } = fromRpcSig(signatureBuffer);

        // Calculate the message hash
        const messageHash = hashPersonalMessage(Buffer.from(message));

        // Recover the public key
        const publicKey = ecrecover(messageHash, v, r, s);

        // Convert the publicKey to a hexadecimal string
        const publicKeyHex = "0x" + publicKey.toString("hex");

        console.log(`Public Key (Hex): ${publicKeyHex}`);

        // Convert the public key to an Ethereum address
        const recoveredAddress = "0x" + pubToAddress(publicKey).toString("hex");

        console.log(`Recovered Address: ${recoveredAddress}`);
      } catch (error) {
        console.error("Error:", error);
      }
    },

    async submitFormTwo() {},
  },
};
</script>
