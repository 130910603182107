<template>
  <div>
    <TopNav />

    <section class="blog-area section-padding-100">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12">
            <ul>
              <li>
                <router-link to="/owner/set-minter">Set Minter</router-link>
              </li>
              <li>
                <router-link to="/owner/set-creator">Set Creator</router-link>
              </li>
              <li>
                <router-link to="/owner/emergency-nft-transfer"
                  >Emergency NFT Transfer</router-link
                >
              </li>
              <li>
                <router-link to="/owner/emergency-coin-transfer"
                  >Emergency Coin Transfer</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
// node modules
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      themain,
    };
  },

  async mounted() {
    var self = this;

    // halaman ini hanya boleh diakses oleh contract owner
    await themain.onlyContractOwnerPage(self, Cookie.get("connectedAddress"));
  },

  methods: {},
};
</script>
