<template>
  <header class="dark-bb">
    <nav
      :class="`navbar navbar-expand-lg ${fixedTop} ${navbarScrolled} ${
        width < 992 && 'bg_color'
      }`"
    >
      <router-link class="navbar-brand" to="/">
        <img src="/images/dexgood-logo.png" alt="Dexgood logo" />
        <span class="text-light"></span>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        @click="themain.toggle('headerMenu')"
      >
        <i class="icon ion-md-menu text-light"></i>
      </button>

      <div class="collapse navbar-collapse" id="headerMenu">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <span
              class="badge badge-danger cursor-pointer"
              @click="themain.openModal('modal-choose-network')"
            >
              {{ chainName }}
            </span>
          </li>
          <li class="nav-item">
            <a
              :class="{ home_active: ishomeActive }"
              class="nav-link"
              href="/"
              role="button"
            >
              Home
            </a>
          </li>
          <li class="nav-item">
            <a @click="menuMarkets" class="cursor-pointer nav-link">
              Markets
            </a>
          </li>
          <li class="nav-item">
            <a @click="menuExchange" class="cursor-pointer nav-link">
              Exchange
            </a>
          </li>
          <!-- <li class="nav-item">
            <router-link to="/p2p" class="nav-link"> P2P </router-link>
          </li> -->
          <!-- <li class="nav-item">
            <router-link to="/coins" class="nav-link"> Cryptos </router-link>
          </li> -->
          <li class="nav-item">
            <router-link to="/user/create-token" class="nav-link">
              Create Token
            </router-link>
          </li>
          <li class="nav-item">
            <a target="_blank" href="/DEXGOOD-Whitepaper.pdf" class="nav-link">
              Whitepaper
            </a>
          </li>
          <li class="nav-item">
            <!-- <a
              :href="`${explorer}/address/${marketAddress}`"
              target="_blank"
              class="nav-link"
            >
              Explorer
            </a>-->
            <a @click="menuExplorer" class="cursor-pointer nav-link">
              Explorer
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://academy.dexgood.com"
              target="_blank"
              class="nav-link"
            >
              Academy
            </a>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto">
          <!-- muncul jika ADA address terkoneksi -->
          <li
            class="dropdown"
            v-if="accountConnected != null && isConnected == 'yes'"
            @click="themain.openDropdown('user-menu')"
            v-bind:id="`user-menu`"
          >
            <div
              class="dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="acc_wrap">
                <img
                  src="/assets/img/avatar.svg"
                  alt="avatar"
                  style="margin-right: 5px; width: 42px"
                />

                <div
                  v-if="accountConnected != null && isConnected == 'yes'"
                  class="acc_itm"
                >
                  <span
                    >{{ accountConnected.substring(0, 5) }}...{{
                      accountConnected.substring(accountConnected.length - 4)
                    }}</span
                  >
                  <h2>
                    {{ parseFloat(mainBalance).toFixed(4) }}
                    {{ currentChainObj.rootCoinSymbol }}
                  </h2>
                </div>
              </div>
              <!-- <table>
                <tr>
                  <td>
                    <img
                      src="/assets/img/avatar.svg"
                      alt="avatar"
                      style="margin-right: 5px; width: 42px"
                    />
                  </td>
                  <td class="text-light" v-if="accountConnected != null">
                    <strong>
                      {{ accountConnected.substring(0, 5) }}...{{
                        accountConnected.substring(accountConnected.length - 4)
                      }}
                    </strong>
                    <br />
                    {{ parseFloat(mainBalance).toFixed(4) }} ETH
                  </td>
                </tr>
              </table> -->
            </div>
            <div id="dropdownMenu" class="dropdown-menu">
              <div
                class="dropdown-header d-flex flex-column align-items-center"
              >
                <div class="figure mb-3">
                  <img src="/assets/img/avatar.svg" alt="" />
                </div>
                <div class="info text-center">
                  <p class="name font-weight-bold mb-0">
                    {{ navUser.user_name_full }}
                  </p>

                  <!-- pada browser metamask account address tidak mau lansung muncul pada components
                      - dia baru muncul saat close/pindah route kemudian reload ke home page
                      - jadi saat pertama connect wallet ambil address dari Cookie yg telah tersimpan
                   -->
                  <p
                    v-if="accountConnected != null && isConnected == 'yes'"
                    class="email text-muted mb-3"
                  >
                    @{{ accountConnected.substring(0, 5) }}...{{
                      accountConnected.substring(accountConnected.length - 4)
                    }}
                  </p>
                </div>
              </div>
              <div class="dropdown-body">
                <ul class="profile-nav">
                  <li class="nav-item">
                    <router-link :to="`/settings/profile`" class="nav-link">
                      <i class="icon ion-md-person"></i>
                      <span>Profile</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="`/settings/wallet`" class="nav-link">
                      <i class="icon ion-md-wallet"></i>
                      <span>My Wallet</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link cursor-pointer"
                      @click="themain.openModal('modal-new-market')"
                    >
                      <i class="icon ion-md-list"></i>
                      <span>Create Market</span>
                    </a>
                  </li>
                  <!-- <li class="nav-item">
                    <router-link class="nav-link" to="/user/create-article">
                      <i class="icon ion-ios-paper"></i>
                      <span>Create Article</span>
                    </router-link>
                  </li> -->
                  <!-- <li class="nav-item">
                    <router-link class="nav-link" to="/user/get-pubkey">
                      <i class="icon ion-md-key"></i>
                      <span>Get Pubkey</span>
                    </router-link>
                  </li> -->
                  <li class="nav-item">
                    <router-link
                      class="nav-link"
                      to="/owner"
                      v-show="showAdminMenu"
                    >
                      <i class="icon ion-md-settings"></i>
                      <span>Contract Owner Menu</span>
                    </router-link>
                  </li>
                  <!-- <li class="nav-item" @click="handleThemes">
                    <a class="nav-link theme_mode">
                      <i class="icon ion-md-moon"></i>
                      <span>Theme</span>
                    </a>
                  </li> -->
                  <li class="nav-item">
                    <button
                      @click="logoutAccount"
                      class="nav-link red"
                      style="background-color: transparent; border: none"
                    >
                      <i class="icon ion-md-power"></i>
                      <span>Disconnect</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <!-- muncul jika TIDAK ADA address terkoneksi -->
          <li class="nav-item" v-else>
            <button @click="checkWallet" class="btn_connect">Connect</button>
          </li>
        </ul>
      </div>
    </nav>

    <!-- modal for new market -->
    <div
      id="modal-new-market"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered d-flex justify-content-center"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 style="opacity: 0" class="modal-title">Choose Market Type</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10.5" cy="10.5" r="10.5" fill="#F6465D" />
                <path
                  d="M16.275 14.122C16.275 14.3694 16.1884 14.5798 16.0152 14.753L14.753 16.0152C14.5798 16.1884 14.3694 16.275 14.122 16.275C13.8745 16.275 13.6641 16.1884 13.4909 16.0152L10.7625 13.2867L8.03409 16.0152C7.86086 16.1884 7.6505 16.275 7.40303 16.275C7.15556 16.275 6.9452 16.1884 6.77197 16.0152L5.50985 14.753C5.33662 14.5798 5.25 14.3694 5.25 14.122C5.25 13.8745 5.33662 13.6641 5.50985 13.4909L8.23826 10.7625L5.50985 8.03409C5.33662 7.86086 5.25 7.6505 5.25 7.40303C5.25 7.15556 5.33662 6.9452 5.50985 6.77197L6.77197 5.50985C6.9452 5.33662 7.15556 5.25 7.40303 5.25C7.6505 5.25 7.86086 5.33662 8.03409 5.50985L10.7625 8.23826L13.4909 5.50985C13.6641 5.33662 13.8745 5.25 14.122 5.25C14.3694 5.25 14.5798 5.33662 14.753 5.50985L16.0152 6.77197C16.1884 6.9452 16.275 7.15556 16.275 7.40303C16.275 7.6505 16.1884 7.86086 16.0152 8.03409L13.2867 10.7625L16.0152 13.4909C16.1884 13.6641 16.275 13.8745 16.275 14.122Z"
                  fill="#FAFAFA"
                />
              </svg>
            </button>
          </div>
          <div class="modal-body p-4">
            <div class="modal-body_wrapper">
              <h2>Choose Market Type</h2>
              <div class="choose_coin">
                <div @click="gotoCreateMarket(2)" class="choose_item">
                  <div class="choose_content">
                    <p>
                      TOKEN/COIN
                      <br />
                      <br />
                      Example; USDT/{{ currentChainObj.rootCoinSymbol }},
                      IDRT/{{ currentChainObj.rootCoinSymbol }}, DOGE/{{
                        currentChainObj.rootCoinSymbol
                      }}
                    </p>
                  </div>
                  <div class="choose_bottom">
                    <span>Token/Coin</span>
                  </div>
                </div>

                <div @click="gotoCreateMarket(1)" class="choose_item">
                  <div class="choose_content">
                    <p>
                      TOKEN/TOKEN
                      <br />
                      <br />
                      Example; DOGE/USDT, IDRT/DOGE
                    </p>
                  </div>
                  <div class="choose_bottom">
                    <span>Token/Token</span>
                  </div>
                </div>

                <div @click="gotoCreateMarket(3)" class="choose_item">
                  <div class="choose_content">
                    <p>
                      COIN/TOKEN
                      <br />
                      <br />
                      Example; {{ currentChainObj.rootCoinSymbol }}/USDT,
                      {{ currentChainObj.rootCoinSymbol }}/IDRT,
                      {{ currentChainObj.rootCoinSymbol }}/DOGE
                    </p>
                  </div>
                  <div class="choose_bottom">
                    <span>Coin/Token</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal for networks -->
    <div
      id="modal-choose-network"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 style="color: #fff" class="modal-title">Choose Network</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="themain.closeModal('modal-choose-network')"
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10.5" cy="10.5" r="10.5" fill="#F6465D" />
                <path
                  d="M16.275 14.122C16.275 14.3694 16.1884 14.5798 16.0152 14.753L14.753 16.0152C14.5798 16.1884 14.3694 16.275 14.122 16.275C13.8745 16.275 13.6641 16.1884 13.4909 16.0152L10.7625 13.2867L8.03409 16.0152C7.86086 16.1884 7.6505 16.275 7.40303 16.275C7.15556 16.275 6.9452 16.1884 6.77197 16.0152L5.50985 14.753C5.33662 14.5798 5.25 14.3694 5.25 14.122C5.25 13.8745 5.33662 13.6641 5.50985 13.4909L8.23826 10.7625L5.50985 8.03409C5.33662 7.86086 5.25 7.6505 5.25 7.40303C5.25 7.15556 5.33662 6.9452 5.50985 6.77197L6.77197 5.50985C6.9452 5.33662 7.15556 5.25 7.40303 5.25C7.6505 5.25 7.86086 5.33662 8.03409 5.50985L10.7625 8.23826L13.4909 5.50985C13.6641 5.33662 13.8745 5.25 14.122 5.25C14.3694 5.25 14.5798 5.33662 14.753 5.50985L16.0152 6.77197C16.1884 6.9452 16.275 7.15556 16.275 7.40303C16.275 7.6505 16.1884 7.86086 16.0152 8.03409L13.2867 10.7625L16.0152 13.4909C16.1884 13.6641 16.275 13.8745 16.275 14.122Z"
                  fill="#FAFAFA"
                />
              </svg>
            </button>
          </div>
          <div class="modal-body" style="color: #fff">
            <table>
              <tr v-for="item in evmNetworks" :key="item">
                <td>
                  <div class="col" @click="changeNetwork(item)">
                    {{ item.networkName }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// node_modules
import Web3 from "web3";
import axios from "axios";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Cookie from "js-cookie";
import detectEthereumProvider from "@metamask/detect-provider";
import MobileDetect from "mobile-detect";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";
import balance from "@/applibs/eth/balance.js";

// components

export default {
  components: {},
  data() {
    return {
      themain,

      explorer: thestore.getBlockchainExplorerDomain(),

      showAdminMenu: false,

      navUser: {},

      domainName: process.env.VUE_APP_DOMAIN_NAME,

      evmNetworks: themain.evmNetworks,

      accountConnected: Cookie.get("connectedAddress"),
      accountInMetamaskApp: Cookie.get("connectedAddress"),
      errorMessage: null,
      isError: false,
      hasProvider: false,
      isConnected: Cookie.get("connected"),

      scrollPosition: 0,
      width: window.innerWidth,

      navbarScrolled: "",
      fixedTop: "",

      isDarkMode: false,

      mainBalance: 0,

      ishomeActive: false,

      chainName: "-",
      currentChainObj: {
        rootCoinSymbol: "",
      },
    };
  },

  async mounted() {
    var self = this;

    // untuk menutup dropdown ketika pindah halaman
    setTimeout(() => {
      this.dropdownCloseHandler();
    }, 50);

    // ambil data chain ID yang aktif
    self.getChosenChainObj();

    // baca koneksi dengan wallet dan address terkoneksi
    await self.getProvider();

    // ambil data user
    await self.getUser();

    // jika ini adalah root, maka fixed top
    if (self.$router.currentRoute._value.path == "/") {
      self.fixedTop = "fixed-top";
    }

    window.addEventListener("scroll", self.updateScrollPosition);

    self.hadleDefaultTheme();

    // baca balance jika ada address terkoneksi
    if (
      Cookie.get("connectedAddress") != null &&
      Cookie.get("connectedAddress") != ""
    ) {
      await self.getMainBalance();
    }

    // dengarkan perubahan dari balance main yang datang dari berbagai komponen
    self.emitter.on("fe-main_balance", async function (thebalance) {
      self.mainBalance = thebalance;
    });

    // deteksi apakah routenya pada halaman home
    const route = self.$route.href;
    if (route === "/") {
      self.ishomeActive = true;
    }
  },

  beforeUnmount() {
    var self = this;
    window.removeEventListener("scroll", self.updateScrollPosition);
    window.ethereum?.removeListener("accountsChanged", self.refreshAccounts);

    // setTimeout(() => {
    //   this.dropdownCloseHandler();
    // }, 2000);
  },

  methods: {
    getChosenChainObj() {
      var self = this;

      const chosenChainId = themain.getChosenChainId();

      // cari evm network data
      const evmNetworkObj = themain.evmNetworks.filter(
        (element) => element.chainId === chosenChainId
      );

      self.chainName = evmNetworkObj[0].networkName;

      self.currentChainObj = evmNetworkObj[0];
    },

    async getUser() {
      var self = this;
      self.navUser = await thestore.getUser(Cookie.get("connectedAddress"));
    },

    async getProvider() {
      var self = this;

      const provider = await detectEthereumProvider({ silent: true });
      self.hasProvider = Boolean(provider);

      if (provider) {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        self.refreshAccounts(accounts);
        window.ethereum.on("accountsChanged", self.refreshAccounts);
      }
    },

    refreshAccounts(accounts) {
      var self = this;

      if (accounts.length > 0) {
        self.updateWallet(accounts);
      } else {
        self.accountConnected = null;
        Cookie.set("connected", "no");
        Cookie.remove("connectedAddress");
        self.isConnected = "no";
      }
    },

    async updateWallet(accounts) {
      var self = this;

      if (
        accounts.length > 0 &&
        accounts[0] !== Cookie.get("connectedAddress")
      ) {
        window.location.reload();
      }

      self.accountConnected = accounts[0];
      self.registerMyAccount(accounts);
      Cookie.set("connectedAddress", accounts[0]);
    },

    async checkWallet() {
      var self = this;
      let userAgent = navigator.userAgent.toString();
      const isMobile = new MobileDetect(window.navigator.userAgent);
      const isMetaMaskInstalled = typeof window.ethereum !== "undefined";
      if (isMetaMaskInstalled) {
        self.handleConnect();
      } else if (isMobile.mobile()) {
        if (userAgent.includes("MetaMaskMobile")) {
          self.handleConnect();
        } else {
          window.location.href = `https://metamask.app.link/dapp/${self.domainName}`;
          return;
        }
      } else {
        var rpcOption = {};
        rpcOption[process.env.VUE_APP_CHAIN_ID] = process.env.VUE_APP_RPC_URL;

        window.ethProvider = new WalletConnectProvider({
          rpc: rpcOption,
        });
        //  Enable session (triggers QR Code modal)
        await window.ethProvider.enable();
        const web3 = new Web3(window.ethProvider);
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          await self.registerMyAccount(accounts);
        } else {
          alert("Please connect your address");
        }
      }
    },

    async handleConnect() {
      var self = this;
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        self.isError = false;
        self.updateWallet(accounts);
        console.log("account:", accounts);

        Cookie.set("connected", "yes");
        self.isConnected = "yes";

        Cookie.set("connectedAddress", accounts[0]);

        // window.location.reload();
      } catch (err) {
        self.isError = true;
        self.errorMessage = err.message;
        Cookie.set("connected", "no");
      }
    },

    async registerMyAccount(accounts) {
      Cookie.set("connected", "yes");
      Cookie.set("connectedAddress", accounts[0]);

      // jika belum ada akun, akan ter-register langsung di server
      axios
        .post(`${themain.baseApi}/users/connect-me`, {
          address: accounts[0],
        })
        .then(async (res) => {
          if (res.data.success == true) {
            console.log("resRedister: ", res);
          }
        });
    },

    async logoutAccount() {
      var self = this;

      self.accountConnected = null;
      Cookie.set("connected", "no");
      Cookie.remove("connectedAddress");
      alert(
        "We absolutely have no control over your wallet. Please proceed with manual disconnection"
      );
      // window.location.reload();
    },

    async gotoCreateMarket(mtipe) {
      var self = this;

      themain.closeModal("modal-new-market");

      self.$router.push({
        path: `/user/create-market`,
        query: {
          mtipe,
          chain_id: themain.getChosenChainId(),
        },
      });
    },

    updateScrollPosition() {
      var self = this;
      this.scrollPosition = window.scrollY;

      if (this.scrollPosition > 200 && this.width > 991) {
        self.navbarScrolled = "navbar--scrolled";
      } else {
        self.navbarScrolled = "";
      }
    },

    hadleDefaultTheme() {
      const theme = Cookie.get("theme");

      const bodyElement = document.body;
      if (theme) {
        bodyElement.id = theme;
      } else {
        Cookie.set("theme", "dark");
      }
    },

    handleThemes() {
      var self = this;

      self.isDarkMode = !self.isDarkMode;
      const bodyElement = document.body;

      if (!this.isDarkMode) {
        bodyElement.id = null;
        bodyElement.id = "dark";
        Cookie.set("theme", "dark");
      } else {
        bodyElement.id = null;
        bodyElement.id = "light";
        Cookie.set("theme", "light");
        console.log("b");
      }
    },

    async getMainBalance() {
      var self = this;
      self.mainBalance = await balance(Cookie.get("connectedAddress"), "ether");
    },

    closeModal() {
      var self = this;

      self.marketSelectNum = null;
      self.isMarketSelected = false;
      themain.closeModal("modal-new-market");
    },

    // TODO: Coba ganti disetiap
    changeNetwork(item) {
      var self = this;

      console.log("item", item);
      console.log("self.$router", self.$router);
      console.log("self.$route", self.$route.name);

      // tutup modal supaya dia tidak muncul terus pada page
      themain.closeModal("modal-choose-network");

      if (self.$route.name == "Market") {
        Cookie.set("chosenChainId", item.chainId);
        self.$router.push({
          path: `/${item.chainId}/markets`,
        });
      } else {
        Cookie.set("chosenChainId", item.chainId);
        window.location.reload();
      }
    },

    menuMarkets() {
      var self = this;

      const chosenChainId = themain.getChosenChainId();

      self.$router.push({
        path: `/${chosenChainId}/markets`,
      });
    },

    menuExchange() {
      var self = this;

      const chosenChainId = themain.getChosenChainId();

      // cari evm network data
      const evmNetworkObj = themain.getChosenChainObj(chosenChainId);

      self.$router.push({
        path: `/${chosenChainId}/markets/${evmNetworkObj.sc_address.toLowerCase()}/${evmNetworkObj.bc_id}`,
        query: {
          pair: evmNetworkObj.pair,
          mtipe: evmNetworkObj.mtipe,
        },
      });
    },

    dropdownCloseHandler() {
      const dropdown = document.getElementById("user-menu");
      const dropdownMenu = document.getElementById("dropdownMenu");

      if (
        dropdown.classList.contains("show") ||
        dropdownMenu.classList.contains("show")
      ) {
        dropdown.classList.remove("show");
        dropdownMenu.classList.remove("show");
      }
    },
  },
};
</script>

<style scoped>
#dark,
#dark header {
  background-color: transparent !important;
  background: transparent !important;
  border: none;
}
.navbar {
  background-color: transparent;
  border: none;
  transition: all 0.3s ease-out;
  background: rgb(94, 94, 94);
  background: linear-gradient(
    0deg,
    rgba(94, 94, 94, 0) 0%,
    rgba(19, 23, 34, 0.75) 100%
  );
}

.navbar--scrolled {
  background-color: #131722; /* Replace with desired background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: add a box shadow */
}

.navbar-brand img {
  height: 25px;
}

.btn_connect {
  border: 1px solid #0ecb81;
  border-radius: 20px;
  padding: 5px 20px;
  color: aliceblue;
  /* margin-left: 10px; */
  background: none;
}

.btn_connect:hover {
  background: #047c4e;
  color: aliceblue !important;
}

.btn_distc {
  width: 100%;
  background: none;
  border: none;

  display: flex;
}

.navbar-nav {
  align-items: center !important;
}

.home_active {
  color: #10b759 !important;
}

.nav-link {
  align-items: center;
}

.nav-link:hover {
  color: #10b759 !important;
}

.acc_wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: -20px;
  color: aliceblue;
  cursor: pointer;
}

.acc_itm {
  margin-top: 10px;
}

.acc_wrap h2 {
  font-size: 14px;
  color: rgb(246, 70, 93);
}

.nav-link {
  display: flex;
  gap: 5px;
}

.nav-link span {
  font-size: 14px;
}

.modal-content {
  background-color: #161a1f !important;
  width: auto;
}

.modal-header {
  border: none;
}

.btn-close {
  background: none;
  border: none;
}

.modal-body_wrapper {
  color: aliceblue;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body_wrapper h2 {
  margin-bottom: 34px;
  font-size: 24px;
}

.choose_coin {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.choose_item {
  width: 221px;
  height: 290px;
  background: #2a2e39;
  border-radius: 10px;
  border: 1px solid #0ecb81;

  position: relative;
  display: grid;
  grid-template-rows: 6fr 1fr;
  cursor: pointer;
}

.selected_coin {
  position: absolute;
  top: -4%;
  right: -4%;
}

.choose_content p {
  margin: 20px 10px;
}

.choose_bottom {
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #0ecb81;
  background: #0ecb81;

  display: flex;
  justify-content: center;
  align-items: center;
}

.choose_bottom span {
  font-size: 16px;
  font-weight: bold;
}

.btn_wrapper {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn_create {
  width: 104px;
  height: 28px;
  border-radius: 4px;
  background-color: #d1ecf1 !important;
  cursor: not-allowed;
  border: none;
  color: rgb(150, 149, 149);
}

.market_selected {
  background-color: #0ecb81 !important;
  color: aliceblue;
  cursor: pointer;
}

.bg_color {
  background-color: #131722;
}

.dropdown-toggle::after {
  border-top: none;
}

@media only screen and (max-width: 767px) {
  .navbar-collapse {
    background-color: #131722;
  }

  .navbar-collapse.show {
    /* position: absolute; */
    top: 0;
    width: 100%;
    /* margin-top: 50px; */
    padding-bottom: 0.7rem;
    z-index: 100;
  }
}
</style>
