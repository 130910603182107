// node_modules
import axios from "axios";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Identicon from "identicon.js";
import Cookie from "js-cookie";

// applibs

var utama = {
  baseApi: process.env.VUE_APP_BASE_API,
  baseEvm: process.env.VUE_APP_BASE_EVM,

  evmNetworks: JSON.parse(process.env.VUE_APP_EVM_NETWORKS),
  defaultChainId: process.env.VUE_APP_DEFAULT_CHAIN_ID,

  openModal(elId) {
    // modal element
    var el = document.getElementById(elId);
    el.classList.add("show");
    el.style.display = "block";

    // create element backdrop
    var elemDiv = document.createElement("div");
    elemDiv.classList.add("modal-backdrop", "fade", "show");

    // append kepada app
    var elapp = document.getElementById("app");
    elapp.appendChild(elemDiv);
  },

  closeModal(elId) {
    // hide modal
    var el = document.getElementById(elId);
    el.classList.remove("show");
    el.style.removeProperty("display");

    // remove backdrop
    var backs = document.getElementsByClassName("modal-backdrop");
    for (let step = 0; step < backs.length; step++) {
      backs[step].remove();
    }
  },

  openDropdown(elId) {
    var elbtn = document.getElementById(elId);
    var menu = document.querySelector(`#${elId} .dropdown-menu`);

    if (elbtn.classList.contains("show")) {
      elbtn.classList.remove("show");
      menu.classList.remove("show");
    } else {
      elbtn.classList.add("show");
      menu.classList.add("show");
    }
  },

  openTabNav(elId, activeClass) {
    var container = document.getElementById(elId);

    // remove dari semua nav link class
    var navLinkClass = container.querySelectorAll(`.nav-link`);
    navLinkClass.forEach((a) => {
      a.classList.remove("active");
    });

    // ambil satu class yang dipilih untuk diaktifkan
    var activeEl = document.querySelector(`#${elId} .${activeClass}-head`);
    activeEl.classList.add("active");

    // tab pane nya dimatikan dulu
    var tabPanes = container.querySelectorAll(`.tab-pane`);
    tabPanes.forEach((a) => {
      a.classList.remove("active");
      a.classList.remove("show");
    });

    // ambil satu class yang dipilih untuk diaktifkan
    var activeTab = document.querySelector(`#${elId} .${activeClass}-body`);
    activeTab.classList.add("active");
    activeTab.classList.add("show");
  },

  toggle(elId) {
    console.log("elId", elId);
    var ele = document.getElementById(elId);

    if (ele.classList.contains("show")) {
      ele.classList.remove("show");
    } else {
      ele.classList.add("show");
    }
  },

  onlyAucExecutorPage(connectedAddress) {
    if (
      connectedAddress.toLowerCase() ==
      process.env.VUE_APP_AUC_EXEC.toLowerCase()
    ) {
      console.log("do nothing");
    } else {
      this.$router.push({
        path: "/",
      });
    }
  },

  shortAddress(theaddress) {
    if (theaddress != null && theaddress != "") {
      return `${theaddress.substr(0, 7)}...${theaddress.substr(-6)}`;
    }
  },

  shortAddressMax(theaddress) {
    if (theaddress != null && theaddress != "") {
      return `${theaddress.substr(0, 5)}...${theaddress.substr(-3)}`;
    }
  },

  shortTxHash(theahash) {
    if (theahash != null && theahash != "") {
      return `${theahash.substr(0, 7)}...${theahash.substr(-6)}`;
    }
  },

  /**
   * Random char https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
   * @param {*} len
   * @param {*} charSet
   * @returns
   */
  randomString(len, charSet) {
    charSet =
      charSet ||
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var randomString = "";
    for (var i = 0; i < len; i++) {
      var randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  },

  /**
   * Digunakan ketika user ada image atau tidak
   */
  theUserImg(imageUrl) {
    if (imageUrl) {
      return imageUrl;
    } else {
      return "/images/default-user.png";
    }
  },

  functionModalBackdropShow(self) {
    self.modalBackdropMain = true;
    self.modalBackdropFade = true;
    self.modalBackdropShow = true;
  },
  functionModalBackdropHide(self) {
    self.modalBackdropMain = false;
    self.modalBackdropFade = false;
    self.modalBackdropShow = false;
  },

  async beforeMountConnectToWallet(self) {
    try {
      if (window.ethereum) {
        self.connectedAddress = Cookie.get("connectedAddress").toLowerCase();
      } else {
        // karena key dari variable, kita akalin seperti ini
        var rpcOption = {};
        rpcOption[process.env.VUE_APP_CHAIN_ID] = process.env.VUE_APP_RPC_URL;

        // Create WalletConnect Provider
        window.ethProvider = new WalletConnectProvider({
          rpc: rpcOption,
        });

        //  Enable session (triggers QR Code modal)
        // await window.ethProvider.enable();

        self.connectedAddress = Cookie.get("connectedAddress").toLowerCase();

        // --- cara yang jelek, tetapi metamask mobile hanya bisa setelah init dan ini hanya 1 kali saja
        //     sangat perlu kita lakukan karena metamask ada problem jika menggunakan provider dari wallet connect
        //     problem tersebut adalah ketika submit transaksi, wallet mobile langsung close window.
        // window.addEventListener('ethereum#initialized', handleEthereum, {
        //   once: true,
        // });
        window.addEventListener(
          "ethereum#initialized",
          function () {
            setTimeout(function () {
              const { ethereum } = window;
              if (ethereum && ethereum.isMetaMask) {
                // console.log('Ethereum successfully detected!');
                alert("Ethereum successfully detected!");
                // Access the decentralized web!
              } else {
                // console.log('Please install MetaMask!');
                alert("Please install MetaMask! (1)");
              }
            }, 3000);
          },
          { once: true }
        );
      }
    } catch (e) {
      // alert('error')
      console.warn(e);
    }
  },

  async networkAlert() {
    var web3;
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else {
      web3 = new Web3(window.ethProvider);
    }

    var chainId = await web3.eth.getChainId();

    const chosenChainId = this.getChosenChainId();

    const chosenChainObj = this.getChosenChainObj(chosenChainId);

    if (parseInt(chainId) != parseInt(chosenChainId)) {
      alert(`Please switch to ${chosenChainObj.networkName} network`);
    }
  },

  async networkChanged(self) {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (chainId) => {
        console.log("chainId", chainId); // JANGAN DI COMMENT
        self.$router.go();
      });
    } else {
      window.ethProvider.on("chainChanged", (chainId) => {
        console.log("chainId", chainId); // JANGAN DI COMMENT
        self.$router.go();
      });
    }
  },

  async accountChanged(self) {
    var web3;
    if (window.ethereum) {
      // PERHATIAN: code ini tidak bisa digunakan walaupun ada di dokumentasi
      // window.ethereum.on("accountsChanged", (accounts) => {
      //   console.log("account changed", accounts);
      //   self.$router.go();
      // });

      web3 = new Web3(window.ethereum);
      web3.eth.getAccounts((error, result) => {
        if (error) {
          console.log(error);
        } else {
          // callback(result);
          // console.log('result', result[0]);
          if (
            result[0].toLowerCase() !=
            Cookie.get("connectedAddress").toLowerCase()
          ) {
            Cookie.set("connectedAddress", result[0]);
            self.$router.go();
          }
        }
      });
    } else {
      window.ethProvider.on("accountsChanged", (accounts) => {
        console.log("account changed", accounts); // JANGAN DI COMMENT
        self.$router.go();
      });
    }
  },

  iconB64Text(hash) {
    var options = {
      foreground: [0, 0, 0, 255], // rgba black
      background: [255, 255, 255, 255], // rgba white
      margin: 0.05, // 20% margin
      size: 40, // 420px square
      format: "svg", // use SVG instead of PNG
    };
    var imgText = new Identicon(hash, options).toString();
    return imgText;
  },

  // disini kita olah data untuk component.
  async updateMarketData(self, themain) {
    const filter_rules = [
      { field: "market_id_bc", op: "equal-num", value: self.market_id_bc },
      { field: "sc_address", op: "equal", value: self.sc_address },
    ];
    var res = await axios({
      method: "get",
      url: `${themain.baseApi}/markets/${Cookie.get(
        "chosenChainId"
      )}?filter_rules=${JSON.stringify(filter_rules)}`,
    });
    // console.log('res.data.data.datas[0]', res.data.data.datas[0]);
    self.market = res.data.data.datas[0];

    const simbols = self.market.pair.split("-");
    self.simbol_a = simbols[0];
    self.simbol_b = simbols[1];
  },

  angkaDenganPersentaseBesar(angka) {
    if (angka > 1000) {
      return angka.toExponential(1);
    } else {
      return angka.toFixed(2);
    }
  },

  getChosenChainId() {
    console.log("Cookie.getchose", Cookie.get("chosenChainId"));

    let chosenChainId = Cookie.get("chosenChainId");
    if (chosenChainId == undefined || chosenChainId == "undefined") {
      chosenChainId = this.defaultChainId;
      Cookie.set("chosenChainId", chosenChainId);
    }

    return chosenChainId;
  },

  getChosenChainObj(requestChainId) {
    const envChains = JSON.parse(process.env.VUE_APP_EVM_NETWORKS);

    const evmChainObj = envChains.filter(
      (element) => element.chainId === requestChainId
    );

    Cookie.set("chainObj", JSON.stringify(evmChainObj));

    return evmChainObj[0];
  },
};

export default utama;
