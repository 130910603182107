import Big from "big.js";

const utama = {
  buyPumpCalculation(self, qty_b_input, sells_include, price) {

    // digunakan untuk mencatat blockchain id dari partial sell
    let partial_sell_bc_id = null;
    // digunakan untuk mencatat semua data yang ada sebelum perubahan partial sell
    let partial_sell_when_full_data = null;

    let sisa = qty_b_input; // Sisa awal sama dengan qty_b_input
    let index = 0; // Index awal adalah 0
    let sisa_arr = [];
    let minus_index = null; // Index ketika sisa menjadi negatif

    // Menghitung sisa untuk setiap sell yang memenuhi syarat
    sells_include.forEach((ele) => {
      sisa = sisa.minus(Big(ele.qty_b_db));
      sisa_arr.push({
        index: index,
        sisa: sisa,
        price: Big(ele.price_db),
        qty_b: Big(ele.qty_b_db),
      });

      if (minus_index == null && sisa.lt(0)) {
        minus_index = index;
      }

      index += 1;
    });

    let final_normal_buy = null;
    const final_smash_list = [];

    // Memilih sell yang masuk ke dalam list pembelian
    if (minus_index == null) {
      sells_include.forEach((ele) => {
        final_smash_list.push(ele);
      });
    } else {
      for (let i = 0; i < minus_index; i++) {
        final_smash_list.push(sells_include[i]);
      }
    }

    // Mengecek apakah ada sisa dari pembelian
    if (minus_index == null) {
      const sisa_pop = sisa_arr.pop();
      sisa_pop["price_db"] = price.toString();
      sisa_pop["price"] = price
        .times(Big(10).pow(self.market.b_decimals))
        .toString();
      sisa_pop["qty_b_db"] = sisa_pop.sisa.toString();
      sisa_pop["qty_b"] = Big(sisa_pop.sisa)
        .times(Big(10).pow(self.market.b_decimals))
        .toString();

      if (Big(sisa_pop["qty_b"]).lte(0)) {
        final_normal_buy = null;
      } else {
        final_normal_buy = sisa_pop;
      }
    } else {
      partial_sell_bc_id = sells_include[minus_index].sell_id_bc;
      partial_sell_when_full_data = JSON.parse(
        document.getElementById(`sell_bc_id_${partial_sell_bc_id}`)
          .innerHTML
      );

      if (minus_index == 0) {
        const persentase_dipakai = qty_b_input.div(
          Big(sells_include[0].qty_b_db)
        );
        const smash_row_zero = sells_include[0];
        smash_row_zero["qty_b_db"] = qty_b_input.toString();
        smash_row_zero["qty_b"] = qty_b_input
          .times(Big(10).pow(self.market.b_decimals))
          .toString();
        smash_row_zero["qty_a_db"] = Big(sells_include[0].qty_a_db)
          .times(persentase_dipakai)
          .toString();
        smash_row_zero["qty_a"] = Big(sells_include[0].qty_a)
          .times(persentase_dipakai)
          .toString();

        final_smash_list.push(smash_row_zero);
      } else {
        if (
          Big(sisa_arr[minus_index].qty_b).gt(
            Big(Math.abs(sisa_arr[minus_index - 1].sisa))
          )
        ) {

          // Anda menggunakan Math.abs untuk mendapatkan nilai absolut dari sisa_arr[minus_index - 1].sisa. 
          // Ini mungkin menyebabkan kesalahan karena Math.abs bekerja dengan tipe data JavaScript biasa (seperti number), 
          // sementara Big.js memiliki metode abs() yang sesuai untuk operasi pada objek Big.
          // var sisa_terakhir = Big(Math.abs(sisa_arr[minus_index - 1].sisa)); // Original
          const sisa_terakhir = sisa_arr[minus_index - 1].sisa.abs(); // perbaikan chatgpt
          const smash_list_last = sells_include[minus_index];
          smash_list_last["qty_b_db"] = sisa_terakhir.toString();
          smash_list_last["qty_b"] = sisa_terakhir
            .times(Big(10).pow(self.market.b_decimals))
            .toString();

          final_smash_list.push(smash_list_last);
        }
      }
    }

    const sellIds = [];
    const qtyBs = [];
    final_smash_list.forEach((el) => {
      sellIds.push(String(el.sell_id_bc));
      qtyBs.push(Big(el.qty_b).toFixed());
    });

    const estimasiHasilA = final_smash_list
      .reduce((accumulator, currentObject) => {
        return accumulator.plus(Big(currentObject.qty_a_db));
      }, Big(0))
      .toNumber();
    self.buyForm.qtyAEstimation = estimasiHasilA;

    // ada kondisi dimana row langsung berkurang jika ketemu partial
    // Row tersebut terlihat ter-eksekusi tetapi sebenarnya belum karena data baru di inputkan lewat form saja
    // Jadi pada code ini kita kembalikan data supaya tidak terjadi kekacauan orderbook
    if (partial_sell_bc_id != null) {
      self.f_sells.forEach((baliknilai, idxbalik) => {
        if (baliknilai.id == partial_sell_when_full_data.id) {
          self.f_sells[idxbalik] = partial_sell_when_full_data;
        }
      });
    }
    
    return {
      sellIds,
      qtyBs,
      final_normal_buy
    }
  }
};

export default utama;
