<template>
  <div>
    <TopNav />

    <section class="container" style="padding-bottom: 100px">
      <section class="row">
        <div class="col">
          <h1 class="text-light mt-4">New Token</h1>

          <h3 class="text-light">Fixed Supply Token</h3>

          <div class="d-flex alert alert-info align-items-center justify-content-center flex-wrap" v-if="newContractAddress != ''">
            <a
              :href="`${explorer}/address/${newContractAddress}`"
              target="_blank"
              class="mr-2"
            >
              {{ newContractAddress }}
            </a>
            <CopyButton :value="newContractAddress" />
          </div>

          <form class="row mt-4" id="create-article-form">
            <div class="col">
              <div class="form-group">
                <input
                  type="text"
                  name="name"
                  v-model="theform.name"
                  class="form-control"
                  placeholder="Name"
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  name="symbol"
                  v-model="theform.symbol"
                  class="form-control"
                  placeholder="Symbol"
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  name="total_supply"
                  v-model="theform.total_supply"
                  class="form-control"
                  placeholder="Total Supply"
                />
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col">
              <div class="btn btn-dark" @click="submitForm()">Submit</div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Web3 from "web3";
import axios from "axios";
import Cookie from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import CopyButton from "@/components/global/CopyButton.vue";

export default {
  components: {
    TopNav,
    Footer,
    CopyButton
  },
  data() {
    return {
      themain,
      explorer: thestore.getBlockchainExplorerDomain(),
      theform: {
        name: "",
        symbol: "",
        total_supply: "",
      },

      newContractAddress: "",
    };
  },

  async beforeMount() {
    var self = this;
    themain.beforeMountConnectToWallet(self);
    themain.networkAlert();
    themain.networkChanged(self);
    themain.accountChanged(self);
  },

  methods: {
    async submitForm() {
      var self = this;

      var web3;
      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
      } else {
        web3 = new Web3(window.ethProvider);
      }

      // name tidak boleh kosong
      if (self.theform.name.length == 0) {
        alert("Name cannot be blank");
        return false;
      }

      if (self.theform.symbol.length == 0) {
        alert("Symbol cannot be blank");
        return false;
      }

      if (self.theform.total_supply.length == 0) {
        alert("Total Supply cannot be blank");
        return false;
      }

      var res = await axios({
        method: "post",
        url: `${themain.baseEvm}/v1/token-auto/fix-supply`,
        data: {},
      });

      // const contractABI = res.data.contractABI;
      const contractBytecode = res.data.contractBytecode;

      console.log("contractBytecode", contractBytecode);

      // Deploy kontrak menggunakan Metamask
      try {
        // Encode constructor arguments to hex
        const encodedParameters = web3.eth.abi.encodeParameters(
          ["string", "string", "uint256"], // Types of constructor arguments
          [self.theform.name, self.theform.symbol, self.theform.total_supply] // Values of constructor arguments
        );

        console.log(
          "[self.theform.name, self.theform.symbol, self.theform.total_supply]",
          [self.theform.name, self.theform.symbol, self.theform.total_supply]
        );

        console.log("encodedParameters", encodedParameters);

        // Combine bytecode and encoded parameters
        const bytecodeWithParameters =
          contractBytecode + encodedParameters.slice(2); // Remove '0x' from encodedParameters

        // Buat instance dari kontrak
        // const contract = new web3.eth.Contract(contractABI);

        const chosenChainId = themain.getChosenChainId();

        // Estimasi gas yang dibutuhkan untuk transaksi
        const gasEstimate = await web3.eth.estimateGas({
          from: Cookie.get("connectedAddress"),
          data: bytecodeWithParameters,
        });

        let gasPrice;
        if (chosenChainId == "247253") {
          // saakuru testnet
          gasPrice = "0x0";
        } else if (chosenChainId == "7225878") {
          // saakuru main net
          gasPrice = "0x0";
        } else {
          // Untuk jaringan lain, set gas price secara dinamis
          gasPrice = await web3.eth.getGasPrice(); // Mendapatkan gas price otomatis
        }

        // Buat objek transaksi untuk menambahkan gas dan nonce
        const tx = {
          from: Cookie.get("connectedAddress"),
          data: bytecodeWithParameters,
          gas: gasEstimate,
          gasPrice,
        };

        // Kirim transaksi ke blockchain
        const receipt = await web3.eth.sendTransaction(tx);

        self.newContractAddress = receipt.contractAddress;
      } catch (error) {
        console.error("Gagal melakukan transaksi:", error);
      }
    },
  },
};
</script>
